import { TextBlock } from "~/types/session";
import type { IVendor } from "../aurora/types";
import type CompanyProfile from "./CompanyProfile.server";
import type Post from "./Post.server";
import type User from "./user/User.server";

export type Document = {
  id: string;
  file?: {
    url: string;
    filename?: string;
  };
  name: string;
  type: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export interface ICompanyProfile {
  id: string;
  about: any;
  logo: { url: string };
  companyName: string;
  uei: string;
  cage: string;
  primaryNaics: string;
  cityState: string;
  website: string;
  profileClaimant?: ICompanyAccount;
  similarCompanies?: CompanyProfile[];
  faqs?: Array<{
    id: string;
    question: string;
    answer: string;
  }>;
  users?: User[];
  insights?: {
    posts?: Post[];
    count?: number;
  };
  assignedDate?: string;
  summary?: Partial<IVendor>;
  employeeCount?: string;
  canClaim: string;
  documents: Document[];
  isPopular: boolean;
  posts: any;
}

export interface IActionFQA {
  id: string;
  data: IFQA;
}

export interface IFQA {
  id?: string;
  question?: string;
  answer?: string;
  companyProfile?: ICompanyProfile;
}

export interface IUser {
  id: string;
  firstName?: string;
  lastName?: string;
  paymentaccounts?: IPaymentAccount[];
  phoneNumber?: string;
  fullName: string;
  email: string;
  profilePicture: {
    url: string;
  };
  profileBanner?: {
    url: string;
  }; // image url
  linkedInProfile?: string;
  biography?: string;
  jobTitle?: string;
  twitterProfile?: string;
  roles: {
    name: string;
  }[];
  password?: string;
  isVerified: boolean;
  hasActiveSubscription?: boolean;
  version?: "V1" | "V2" | "v2" | "v1";
  createdAt?: string;
}

export interface IPaymentAccount {
  validUntil: string;
  createdAt: string;
  id: string;
  referralCode: string;
  subscriptionId: string;
  paymentMethods: {
    id: string;
  }[];
  isTrial: boolean;
  stripeCustomerId?: string;
  users?: IUser[];
  subscriptionSeats?: number;
  subscriptionPlan: ISubscriptionPlan;
  companyAccount: ICompanyAccount;
  tier: ITier;
  openSeats: number;
  email: string;
}

export interface ICompanyAccount {
  claimedProfiles: any;
  id: string;
  companyName?: string;
  companyAddress?: string;
  companyWebsite?: string;
  email?: string;
  paymentAccounts?: IPaymentAccount[];
  logo?: {
    url: string;
  };
  isMediaPublished: boolean;
}

export interface ISubscriptionPlan {
  connect?: {
    id?: string;
    stripePriceId?: string;
  };
  id: string;
  name: string;
  description: string;
  seatAmount: number;
  price: number;
  stripePriceId: string;
  tiers: ITier[];
  platform: {
    name: string;
  };
}

interface ITier {
  id: string;
  name: string;
  description: string;
  seatAmount: number;
  price: number;
}

export interface IPaymentMethod {
  id: string;
  paymentMethodId?: string;
  isDefault?: boolean;
  lastFourDigits?: string;
  brand?: string;
  country?: string;
  expMonth?: string;
  expYear?: string;
  bankName?: string;
  accountHolderType?: string;
  routingNumber?: string;
  paymentMethodType?: string;
}

export enum Platform {
  Defense = "Defense",
  FedCiv = "FedCiv",
  Health = "Health",
}

export interface ISubscription {
  /** The platform this subscription belongs to */
  platform: Platform;
  /** Payment account id */
  accountId: string;
  /** data relating to the holding account (currently payment account) */
  company?: ICompany;
  /** Payment methods related to subscription */
  paymentMethods?: Array<IPaymentMethod>;
  /** Some true/false value commenting the validity of the subscription */
  isValid: boolean;
  /** Date through which subscription is valid */
  validUntil?: string;
  /** Date when subscription was created */
  createdAt?: string;
  /** Some true/false value telling us whether the subscription is a trial */
  isTrial?: boolean;
  /** String containing the id of the subscription */
  id?: string;
  /** Open seats */
  openSeats?: number;
  /** Object containing data relating to plan */
  plan?: IPlan;
  /** Users related to a given subscription */
  users?: User[];
  /** Total users related to a giben subscription */
  usersCount?: number;
  /** Email managing invoices */
  email: string;
  /** referral code */
  referralCode: string;
  /** customer id */
  customerId?: string;
}

export interface ICompany {
  /** id of the holder */
  id?: string;
  /** name of the holder */
  name?: string;
  /** address of the holder */
  address?: string;
  /** website of the holder */
  website?: string;
  /** email of the holder */
  email?: string;
  /** Subscriptions */
  subscriptions?: ISubscription[];
  /** Details */
  details?: {
    logo?: string;
  } | null;
  /**Logo */
  logo?: {
    url: string;
  };
  isMediaPublished: boolean;
}

interface IPlan {
  /** QS1 Plan Id */
  id: string;
  /** Name of the plan */
  name: string;
  /** Stripe price id */
  priceId?: string;
  /** Amount of users available in the subscription */
  seats?: number;
  /** Tier of the plan */
  tier?: {
    /** QS1 Tier ID */
    id?: string;
    /** Name of the tier */
    name?: string;
    /** seats */
    seats?: number;
    /** Price of tier */
    price?: number;
    /** QS1 Description */
    description?: string;
  };
}

export interface IPartner {
  logo: {
    url: string;
  };
  id: string;
  slug: string;
  name: string;
  title: string;
  location: string;
  type: string;
  website: string;
  section_1_Title: string;
  Section_1_text: string | Array<TextBlock>;
  section_1_image: {
    url: string;
  };
  section_2_Title: string;
  Section_2_text: string | Array<TextBlock>;
  section_2_image: {
    url: string;
  };
  section_3_Title: string;
  Section_3_text: string | Array<TextBlock>;
  section_3_image: {
    url: string;
  };
  createdAt: string;
}

/* Saved Searches Types */

export type IUpdateSavedSearchData = {
  name?: string;
  active?: boolean;
  frequency?: string;
};

export type IReponseSavedSearch = {
  name: string;
  id: string;
};

export type IUpdateSavedSearch = {
  updateSavedSearch: IReponseSavedSearch;
};

export type IDeleteSavedSearch = {
  deleteSavedSearch: IReponseSavedSearch;
};

export interface IDiscount {
  logo?: {
    url: string;
  };
  id: string;
  name: string;
  benefit_desc: string;
}

export interface IFedSavvyReport {
  agency: string;
  reportLink: string;
  createdAt: string;
}

export interface IAdvertisement {
  title: string;
  thumbnail: {
    url: string;
  };
  pages?: string;
  platform?: string;
  startDate: string;
  endDate: string;
  adLink: string;
  createdAt: string;
  publishedAt: string;
}

export interface FieldData {
  id: string;
  name: string;
  fieldType: string;
  value: string;
}

export interface SectionData {
  id: string;
  name: string;
  fields: FieldData[];
}

export interface InnerTabData {
  id: string;
  name: string;
  sections: SectionData[];
}

export interface PipelineOpportunityData {
  id: string;
  innerTabs: InnerTabData[];
}

export interface StageData {
  id: string;
  name: string;
  description: string;
  order: number;
  type: string;
  pipelineOpportunities: PipelineOpportunityData[]; // Updated to include PipelineOpportunities
  value: string;
  createdAt: string;
  updatedAt: string;
}

export interface PipelineData {
  id: string;
  name: string;
  owner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  type: string;
  stages: StageData[];
  notes?: Array<{
    author: {
      fullName: string;
    };
    content: string | Array<TextBlock>;
  }>;
  createdAt: string;
  updatedAt: string;
}
